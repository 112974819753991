<template>
  <!--    <div>-->
  <!--        <el-form-item :label-width="field.label && field.label.labelWidth ? field.label.labelWidth + 'px' : ''"-->
  <!--                      :class="[field.label && field.label.labelPosition ? 'el-form&#45;&#45;labelPosition-' + field.label.labelPosition : 'el-form&#45;&#45;labelPosition-top']">-->
  <!--            <template slot="label">-->
  <!--                <Tooltip v-if="field.label && field.label.labelTooltip"-->
  <!--                         :labelTooltip="field.label.labelTooltip"-->
  <!--                         :content="field.label && field.label.name ? $t(field.label.name) : ''"></Tooltip>-->
  <!--                <span v-else class="labelWrap">{{field.label && field.label.name ? $t(field.label.name) : ''}}</span>-->
  <!--            </template>-->
  <!--            <div v-if="value"-->
  <!--                 v-html="value"-->
  <!--                 class="pl-2 el-form-text">-->
  <!--&lt;!&ndash;                <span style="word-wrap: break-word">{{ value }}</span>&ndash;&gt;-->
  <!--            </div>-->
  <!--            <div v-else-->
  <!--                 class="el-form-text">-->
  <!--                <span class="no-date-title">{{ $t('system.no_data') }}</span>-->
  <!--            </div>-->
  <!--        </el-form-item>-->
  <!--    </div>-->

  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <div
        class="pl-2"
        style="line-height: 1.15"
        v-html="value"
        v-if="value"
    >
      <!--                <span style="word-wrap: break-word">{{ value }}</span>-->
    </div>
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </base-form-item>
</template>

<script>
import abstractForm from '../../mixin/index';
import BaseFormItem from '../../UI/FormItem.vue';

export default {
  mixins: [abstractForm],
  name: 'index',
  components: {BaseFormItem},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.el-form-text {
  display: inline-block;
  width: 100%;
}
</style>
